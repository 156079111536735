import { computed, DestroyRef, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  ActionAddLineItem,
  CartMetadataDto,
  CartPatchRequestBodyDto,
  CartResponseDto,
  PermissionsEnum,
  PurchaseApiService,
} from '@ev-portals/cp/frontend/shared/api-client';
import {
  AuthenticationService,
  SelectedLocationService,
} from '@ev-portals/cp/frontend/shared/auth/util';
import { CartProxyService } from '@ev-portals/cp/frontend/shared/data-access';
import { notNullFilter } from '@ev-portals/ev/frontend/util';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  first,
  mergeMap,
  Observable,
  of,
  repeat,
  skip,
  skipWhile,
  Subject,
  switchMap,
  take,
  tap,
  throwIfEmpty,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartFacade {
  #destroyRef = inject(DestroyRef);
  #purchaseApiService = inject(PurchaseApiService);
  #selectedLocationService = inject(SelectedLocationService);
  #cartProxyService = inject(CartProxyService);
  #authService = inject(AuthenticationService);

  public $cart = signal<CartResponseDto | null>(null);

  $loading = computed(() => !this.$cart());
  $calculating = signal<boolean>(false);
  $updating = signal<boolean>(false);

  // for order confirmation page
  $orderConfirmData = computed(() => {
    const cart = this.$cart();
    if (!cart) return;

    const data: OrderConfirmData = {
      lineItemGroups: cart.lineItemGroups.map(lig => ({
        title: `${lig.salesOrgDescription},${lig.distributionChannelDescription}`,
        poNumber: lig.poNumber,
      })),
      cartId: cart.id ?? '',
    };

    return data;
  });

  #_requestError$ = new Subject<string>();
  requestError$ = this.#_requestError$.asObservable().pipe(tap(error => console.error(error)));

  constructor() {
    // effects
    effect(
      () => {
        const cart = this.$cart();

        // everytime cart changes, we update the metadata (for the number of items in the cart)
        this.#cartProxyService.setNumberOfCartItems(cart?.lineItems.length);
      },
      { allowSignalWrites: true },
    );

    // subscriptions
    this.#syncAddedCartItems();
    this.#loadCartDataOnAddressChanges();

    // initially load cart and cart metadata if user is sales user and has checkout permission
    this.#authService.user$
      .pipe(
        notNullFilter(),
        filter(
          user =>
            user.roles.includes('SALES_USER') &&
            user.permissions.includes(PermissionsEnum.Checkout),
        ),
        switchMap(() => this.loadCartMetaData()),
        switchMap(() => this.loadCartData()),
        take(1),
      )
      .subscribe();
  }

  /**
   * - needs to be subscribed to, to trigger the request
   */
  loadCartData(calculationRequired = false): Observable<CartResponseDto> {
    return this.#purchaseApiService
      .getCurrentCart({ pollUntilCalculateIsDone: calculationRequired })
      .pipe(
        tap(cart => this.updateCartLocally(cart)),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  /**
   * - needs to be subscribed to, to trigger the request
   */
  loadCartMetaData(): Observable<CartMetadataDto> {
    return this.#purchaseApiService.getCartMetadata().pipe(
      take(1),
      tap(metadata => this.#cartProxyService.setNumberOfCartItems(metadata.lineItemCount)),
      catchError(() => {
        this.#cartProxyService.setNumberOfCartItems(undefined);
        return EMPTY;
      }),
    );
  }

  /**
   * - for all the patch calls
   * - manages the "updating" state of the cart (used for displaying spinners)
   * - needs to be subscribed to, to trigger the request
   */
  updateCartRemotely(actions: CartAction[]): Observable<CartResponseDto> {
    this.$updating.set(true);

    return this.#purchaseApiService.patchCurrentCart({ body: { actions } }).pipe(
      first(),
      catchError(err => {
        this.#_requestError$.next(err.error.message);
        return EMPTY;
      }),
      finalize(() => this.$updating.set(false)),
      tap(() => this.$updating.set(false)),
    );
  }

  updateCartLocally(cart: CartResponseDto): Observable<CartResponseDto> {
    this.$cart.set(cart);

    return of(cart);
  }

  patchCartLocally(partialCart: Partial<CartResponseDto>): Observable<CartResponseDto> {
    const cart = this.$cart();
    if (!cart) {
      throw new Error(`Cart is not loaded`);
    }

    const updatedCart: CartResponseDto = { ...cart, ...partialCart };

    return this.updateCartLocally(updatedCart);
  }

  // Updates the local state after completing
  updateAllItems(payload: Partial<UpdateAllItemsPayload>): Observable<CartResponseDto | undefined> {
    const cart = this.$cart();

    if (!cart) {
      throw new Error(`Cart is not loaded`);
    }

    const actions: CartAction[] = [];

    // Loop through all lineItemGroups and lineItems and collect the actions for remote updates
    cart.lineItems.forEach(lineItem => {
      if (payload.pon) {
        lineItem.poNumber = payload.pon;
        actions.push({
          action: 'setLineItemPurchaseOrderNumber',
          lineItemId: lineItem.id,
          poNumber: payload.pon as string,
        });
      }
      if (payload.rdd) {
        lineItem.requestedDeliveryDate = payload.rdd;
        actions.push({
          action: 'changeRequestedDeliveryDate',
          lineItemId: lineItem.id,
          requestedDeliveryDate: payload.rdd as string,
        });
      }
      if (payload.rddTimeFrom) {
        lineItem.requestedDeliveryTimeFrom = payload.rddTimeFrom;
        actions.push({
          action: 'setRequestedDeliveryTimeFrom',
          lineItemId: lineItem.id,
          requestedDeliveryTime: payload.rddTimeFrom as string,
        });
      }
      if (payload.rddTimeTo) {
        lineItem.requestedDeliveryTimeTo = payload.rddTimeTo;
        actions.push({
          action: 'setRequestedDeliveryTimeTo',
          lineItemId: lineItem.id,
          requestedDeliveryTime: payload.rddTimeTo as string,
        });
      }
    });

    // Updates local state right after completing the remote actions
    return this.updateCartRemotely(actions);
  }

  changeDeliveryDate(
    lineItemId: string,
    requestedDeliveryDate: string,
  ): Observable<CartResponseDto | undefined> {
    return this.updateCartRemotely([
      {
        action: 'changeRequestedDeliveryDate',
        lineItemId,
        requestedDeliveryDate,
      },
    ]).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  changeLineItemPurchaseOrderNumber(lineItemId: string, poNumber: string) {
    return this.updateCartRemotely([
      { action: 'setLineItemPurchaseOrderNumber', lineItemId, poNumber },
    ]).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  /**
   * Note: here the consumer have to subscribe to the returned observable
   */
  calculatePrices(): Observable<CartResponseDto | undefined> {
    this.$calculating.set(true);

    return this.updateCartRemotely([{ action: 'recalculate' }]).pipe(
      switchMap(cart => {
        if (cart.calculationStatus === 'CALCULATED') {
          this.updateCartLocally(cart);
          return of(cart);
        } else {
          return this.#pollCartDataUntilCalculated$();
        }
      }),
      finalize(() => this.$calculating.set(false)),
      tap(() => this.$calculating.set(false)),
      take(1),
    );
  }

  /**
   * Note: here the consumer have to subscribe to the returned observable
   */
  placeOrder(termsUrls: string[]): Observable<CartResponseDto> {
    return this.updateCartRemotely([
      {
        action: 'acceptTermsAndConditions',
        termsAndConditionsAcceptance: [
          ...termsUrls.map(url => ({
            acceptance: true,
            salesorg: '',
            timestamp: new Date().toISOString(),
            url,
          })),
        ],
      },
      {
        action: 'placeOrder',
      },
    ]).pipe(
      switchMap(cart => {
        // If cart is emptied, we're done.
        if (!cart?.lineItemGroups.length) {
          return this.updateCartLocally(cart);
        } else {
          // Otherwise, we have to poll for the emptied cart
          return this.#pollCartDataUntilEmpty$();
        }
      }),
    );
  }

  deleteCurrentCart(): Observable<CartResponseDto> {
    if (!this.$cart) {
      throw new Error(`Cart is not loaded`);
    }

    // Update the remote cart, with the actions we collected previosly
    return this.#purchaseApiService.deleteCurrentCart().pipe(
      take(1),
      tap(cart => this.updateCartLocally(cart)),
    );
  }

  removeLineItem(lineItemId: string): Observable<CartResponseDto | undefined> {
    if (!this.$cart) throw new Error(`Cart is not loaded`);

    return this.updateCartRemotely([{ action: 'removeLineItem', lineItemId }]).pipe(
      tap(cart => this.updateCartLocally(cart)),
    );
  }

  /**
   * - for adding a new item to the cart from PDP or for saved carts
   */
  addCartItem(articleId: string, quantity: number, uom: string): Observable<CartResponseDto> {
    const action: ActionAddLineItem = {
      action: 'addLineItem',
      article: articleId,
      quantity,
      uom,
    } as const;

    return this.#purchaseApiService.patchCurrentCart({ body: { actions: [action] } }).pipe(
      tap(cart => {
        this.updateCartLocally(cart);
        this.#cartProxyService.setSuccessMessage($localize`Item successfully added to cart`);
      }),
      catchError(err => {
        this.#cartProxyService.setRequestError(err.error.message);
        return EMPTY;
      }),
      take(1),
    );
  }

  /**
   * Currently not used, but in the future we could add it to the queue after placing an order
   */
  #pollCartDataUntilEmpty$(): Observable<CartResponseDto> {
    return this.loadCartData().pipe(
      repeat({ count: 10, delay: 3000 }),
      skipWhile(cart => !!cart?.lineItemGroups.length),
      first(),
      throwIfEmpty(() => {
        const errorMessage = $localize`Could not empty the cart`;
        this.#_requestError$.next(errorMessage);

        return new Error(errorMessage);
      }),
    );
  }

  /**
   * We try to poll the cart data until the calculation status is 'CALCULATED', 10 times, with a delay of 3 seconds
   * In the future, we could improve this part by adding it to the queue
   *  */
  #pollCartDataUntilCalculated$(): Observable<CartResponseDto> {
    return this.loadCartData().pipe(
      repeat({ count: 10, delay: 3000 }),
      // If it's in progress, we skip and wait
      skipWhile(cart => cart.calculationStatus === 'CALCULATION_IN_PROGRESS'),
      // If it's failed, we handle the error
      mergeMap(value => {
        this.$cart.set(value);

        /**
         * If the calculation failed, but there are no errors on the cart, we have to throw a special exception
         * If it failed, but we have errors to show, we continue and render the errors
         */

        if (value.calculationStatus === 'CALCULATION_FAILED' && !this.#cartHasErrors(value)) {
          const errorMessage = $localize`Price calculation failed`;
          this.#_requestError$.next(errorMessage);

          return EMPTY;
        } else {
          return of(value);
        }
      }),
      throwIfEmpty(() => {
        const errorMessage = $localize`Could not calculate the prices`;
        this.#_requestError$.next(errorMessage);

        return new Error(errorMessage);
      }),
      first(),
    );
  }

  #loadCartDataOnAddressChanges(): void {
    toObservable(this.#selectedLocationService.$selectedLocation)
      .pipe(
        filter(Boolean),
        skip(1),
        switchMap(() => this.loadCartMetaData()),
        switchMap(() => this.loadCartData()),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #syncAddedCartItems(): void {
    this.#cartProxyService.addNewCartItem$
      .pipe(
        switchMap(payload => {
          return this.addCartItem(payload.articleId, payload.quantity, payload.uom);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #cartHasErrors(cart: CartResponseDto): boolean {
    return (
      !!cart.errors?.length ||
      cart.lineItemGroups.some(lig => lig.errors?.length) ||
      cart.lineItems.some(li => !!li.errors?.length || !!li.invalidReasons?.length)
    );
  }
}

export interface UpdateAllItemsPayload {
  pon: string | null;
  rdd: string | null; // yyyy-MM-dd
  rddTimeFrom: string | null; // 00:00
  rddTimeTo: string | null; // 00:00
}

export type CartAction = CartPatchRequestBodyDto['actions'][0];

export type CalculationStatus =
  | 'CALCULATED'
  | 'CALCULATION_REQUIRED'
  | 'CALCULATION_IN_PROGRESS'
  | 'CALCULATION_FAILED'
  | undefined;

export type LineItemGroupInfo = Record<number, string>;

export interface OrderConfirmData {
  lineItemGroups: {
    title: string;
    poNumber?: string;
  }[];
  cartId: string;
}
