import { PermissionsEnum } from '@ev-portals/cp/frontend/shared/api-client';

/**
 * Extracts sales area permissionMap from a given permissionMap object for a specific sales area.
 * @param {Object} permissionMap - Object containing sales area permissionMap.
 * @param {string} salesArea - Sales area for which permissionMap need to be extracted.
 * @return {Object} - Object with sales area permissionMap.
 */
export const extractSalesAreaPermissions = (
  permissionMap: { [salesArea: string]: PermissionsEnum[] },
  salesArea: string,
): Record<string, boolean> => {
  const salesAreaPermissions = permissionMap[salesArea];

  return Object.values(PermissionsEnum).reduce(
    (result, current) => ({
      ...result,
      [current]: salesAreaPermissions?.includes(current),
    }),
    {} as Record<PermissionsEnum, boolean>,
  );
};
