import { inject, Injectable } from '@angular/core';
import {
  ActionActivateSavedCart,
  ActionRemoveLineItemSavedCart,
  CartLineItemDto,
  CartResponseDto,
  PurchaseApiService,
  SaveCartLineItemDto,
  SavedCartLineItemDto,
  SavedCartResponseDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { map, Observable, tap } from 'rxjs';

import { CartFacade } from './cart.facade';

@Injectable({
  providedIn: 'root',
})
export class SavedCartFacade {
  #purchaseApiService = inject(PurchaseApiService);
  #cartFacade = inject(CartFacade);

  getSavedCartById(savedCartId: string): Observable<SavedCartResponseDto> {
    return this.#purchaseApiService.getSavedCartById({ id: savedCartId });
  }

  getSavedCarts(): Observable<SavedCartResponseDto[]> {
    return this.#purchaseApiService.getSavedCartsList().pipe(map(res => res.savedCarts));
  }

  activateSavedCart(savedCartId: string): Observable<CartResponseDto> {
    const actionObject: ActionActivateSavedCart = {
      action: 'activateSavedCart',
      savedCartId,
    };

    return this.#purchaseApiService
      .patchCurrentCart({
        body: { actions: [actionObject] },
      })
      .pipe(tap(cartResponse => this.#cartFacade.updateCartLocally(cartResponse)));
  }

  removeLineItemFromSavedCart(
    savedCartId: string,
    lineItem: SavedCartLineItemDto,
  ): Observable<SavedCartResponseDto> {
    const actionObject: ActionRemoveLineItemSavedCart = {
      action: 'removeLineItemSavedCart',
      lineItemId: lineItem.id,
    };

    return this.#purchaseApiService.patchSavedCart({
      id: savedCartId,
      body: { actions: [actionObject] },
    });
  }

  removeSavedCart(savedCartId: string): Observable<void> {
    return this.#purchaseApiService.deleteSavedCart({ id: savedCartId });
  }

  saveCart(name: string, lineItems: CartLineItemDto[]): Observable<SavedCartResponseDto> {
    const lineItemsToSave = lineItems.map(lineItem => {
      const saveCartLineItemDto: SaveCartLineItemDto = {
        articleId: lineItem.article.key,
        quantity: lineItem.totalQuantity,
        uom: lineItem.uom,
      };

      return saveCartLineItemDto;
    });

    return this.#purchaseApiService.createNewSavedCart({
      body: { lineItems: lineItemsToSave, name },
    });
  }
}
