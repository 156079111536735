import { inject, Injectable } from '@angular/core';
import { PurchaseApiService } from '@ev-portals/cp/frontend/shared/api-client';
import { tap } from 'rxjs';

import { CartFacade } from './cart.facade';

@Injectable({
  providedIn: 'root',
})
export class CartUploadDownloadService {
  #purchaseApiService = inject(PurchaseApiService);
  #cartFacade = inject(CartFacade);

  downloadCart() {
    return this.#purchaseApiService.downloadCartTemplate();
  }

  uploadCart(overwriteCart: boolean, file: File) {
    return this.#purchaseApiService
      .uploadCartTemplate({ body: { file, overwriteCart } })
      .pipe(tap(cart => this.#cartFacade.updateCartLocally(cart)));
  }
}
